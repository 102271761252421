import React from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import logo from '../assets/logo.jpg'

const Header = () => {
    const navigate = useNavigate();


    return (
        <StyledBox>
            <div className='left'>
                <span className='logo'>
                    <img src={logo}/>
                </span>
            </div>
            <div className='right'>
                <span onClick={() => navigate('/')} className='navLink'>Home</span>
                <span onClick={() => navigate('/about')} className='navLink'>About Us</span>
                <span onClick={() => navigate('/product-service')} className='navLink'>Products and Services</span>
                <span onClick={() => navigate('/contact')} className='navLink'>Contact Us</span>
            </div>
        </StyledBox>
    )
}

export default Header



const StyledBox = styled.div`

display: flex;
flex-direction: row;
height: 4rem;
width: 90%;
justify-content: space-between;
padding: 0 5%;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
align-items: center;
background-color: var(--backgroundColor);
color: var(--color);
position: fixed;
z-index: 2000;


.left {

}

.right {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

.navLink {
    font-weight: 500;
}

.navLink:hover {
    font-weight: 600;
    cursor: pointer;
}

.logo > img {
    height: 3.3rem;
}

`


