import React, { useState } from 'react'
import styled from 'styled-components'
import bg1 from '../assets/bg1.jpg'
import bg2 from '../assets/bg2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBinoculars, faBriefcase, faChartSimple, faClipboardList, faEnvelope, faLocationDot, faMobile } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router'
import redirectIcon from '../assets/redirect.png'

const LandingContent = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const handleOpenRegortalent = () => {
        window.open("https://ai.regortalent.com", "_blank");
    }

    return (
        <StyledBox>
            <div className='heroSection'>
                <span className='heroTitle'>Making <span className='heroTitleClr'>your ideas</span> <br /> happen!</span>

                <div className='heroBtnBox'>
                    <button className='heroBtn1' onClick={() => navigate('/contact')}>Get Started</button>
                    <button className='heroBtn2' onClick={() => navigate('/product-service')}>Our Products</button>
                </div>
            </div>

            <div className='desc'>
                <div className='descLeft'>
                    <img src={bg2} alt='bg2' />
                </div>
                <div className='descRight'>
                    <span className='descTitle'>At Regor Labs, we believe in the transformative power of AI-based technology solutions.</span>
                    <span className='descTitleS'>Whether you're looking to scale your operations, improve customer experiences, or streamline processes, our expertise and cutting-edge solutions can help you achieve your goals. Embrace the future of business with AI, and unlock your business's full potential for growth and cost optimization.</span>
                    <div className='descTextBox'>
                        <span className='descText'>Ready to take the leap into the world of AI-driven success? <br /> <span className='link' onClick={() => navigate('/contact')}>Contact us</span> today to explore how our AI-based technology solutions can drive your business forward. Your success story begins here.</span>
                    </div>
                </div>
            </div>

            <div className='service'>
                <span className='serviceText'>In today's fast-paced and competitive business landscape, staying ahead requires innovation, efficiency, and the ability to harness the power of data. At Regor Labs, we offer cutting-edge AI-based technology solutions that empower businesses across industries to not only push growth boundaries but also optimize costs effectively. Discover how our solutions can revolutionize your operations and drive success.</span>

                <span className='serviceText'><b>Expertise Across Industries: </b> We understand the unique challenges of different industries and tailor our solutions to meet your specific needs, whether you're in healthcare, finance, manufacturing, or any other sector.</span>

                <div className='serviceCardBox'>
                    <div className='serviceCard'>
                        <FontAwesomeIcon icon={faBriefcase} className='fa-4x' style={{ color: "var(--lightOrange)", }} />
                        <div className='cardTextBox'>
                            <span className='cardTitle'>Proven Results</span>
                            <span className='cardText'>Our AI solutions have a track record of delivering tangible results, from revenue growth to cost reduction.</span>
                        </div>
                    </div>


                    <div className='serviceCard'>
                        <FontAwesomeIcon icon={faClipboardList} className='fa-4x' style={{ color: "var(--lightOrange)", }} />
                        <div className='cardTextBox'>
                            <span className='cardTitle'>Customization</span>
                            <span className='cardText'>We collaborate closely with your team to develop bespoke solutions that align with your business objectives.</span>
                        </div>
                    </div>

                    <div className='serviceCard'>
                        <FontAwesomeIcon icon={faChartSimple} className='fa-4x' style={{ color: "var(--lightOrange)", }} />
                        <div className='cardTextBox'>
                            <span className='cardTitle'>Continuous Innovation</span>
                            <span className='cardText'>AI is a rapidly evolving field, and we stay at the forefront of technological advancements to ensure you always have access to the latest solutions.</span>
                        </div>
                    </div>


                    <div className='serviceCard'>
                        <FontAwesomeIcon icon={faBinoculars} className='fa-4x' style={{ color: "var(--lightOrange)", }} />
                        <div className='cardTextBox'>
                            <span className='cardTitle'>Cost Optimization</span>
                            <span className='cardText'>Our AI solutions are designed not only to drive growth but also to optimize costs, ensuring a positive impact on your bottom line.</span>
                        </div>
                    </div>
                </div>
            </div>


            <div className='action'>
                <div className='actionLeft'>
                    <span className='actionText'>Take the first step toward a more efficient, data-driven, and competitive future. <span className='linkBlue link' onClick={() => navigate('/contact')}>Contact us</span> today to learn how our AI-based technology solutions can revolutionize your organization. Together, we'll shape a brighter tomorrow powered by innovation.</span>
                </div>

                <div className='actionRight'>
                    <button onClick={() => navigate('/contact')}>Contact Us</button>
                    <button onClick={() => handleOpenRegortalent()}>Visit RegorTalent <img src={redirectIcon}/></button>
                </div>
            </div>

            <div className='contact'>
                <span className='contactText'>Don't miss out on the AI revolution. <span className='link' onClick={() => navigate('/contact')}>Contact us</span> today to explore the possibilities!</span>

                <div className='contactBox'>
                    <div className='contactCard'>
                        <FontAwesomeIcon icon={faLocationDot} style={{ color: "var(--lightOrange)", }} className='fa-3x' />
                        <span className='contactCardTitle'>ADDRESS</span>
                        <span className='contactCardText'>Sector 79, Noida 201301 IN</span>
                    </div>
                    <div className='contactCard'>
                        <FontAwesomeIcon icon={faEnvelope} style={{ color: "var(--lightOrange)", }} className='fa-3x' />
                        <span className='contactCardTitle'>EMAIL</span>
                        <span className='contactCardText'>admin@regorlabs.com</span>
                    </div>
                </div>
            </div>

            {/* <div className='form'>
                <div className='inputBox'>
                    <input placeholder='Name' value={name} type='text' className='inputName' onChange={(e) => setName(e.target.value)} />
                    <input placeholder='Email' value={email} type='text' className='inputEmail' onChange={(e) => setEmail(e.target.value)} />
                </div>
                <input placeholder='Subject' value={subject} type='text' className='inputSub' onChange={(e) => setSubject(e.target.value)} />
                <textarea placeholder='Message' value={message} className='inputMsg' onChange={(e) => setMessage(e.target.value)} />
                <button className='btn'>Send Message</button>
            </div> */}

        </StyledBox>
    )
}

export default LandingContent



const StyledBox = styled.div`
width: 100%;


.heroSection {
    background-image: url(${bg1});
    background-size: cover;
    background-repeat: no-repeat; 
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 4rem;
    justify-content: center;
    align-items: center;

}


.heroTitle {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--blue);
    text-align: center;

}

.heroTitleClr {
    color: var(--lightOrange);
}

.heroBtnBox {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.heroBtn1 {
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.7rem 1.5rem;
    border-radius: 0.5rem;
    color: var(--white);
    border: 0.1rem solid var(--blue);
    background-color: var(--blue);
}

.heroBtn2 {
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.7rem 1.5rem;
    border-radius: 0.5rem;
    color: var(--white);
    border: 0.1rem solid var(--lightOrange);
    background-color: var(--lightOrange);
}

.desc {
    display: flex;
    flex-direction: row;
    padding: 3rem 5%;
}

.descLeft {
    width: 50%;
}

.descLeft img {
    width: 80%;
}

.descRight {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.descTitle {
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--blue);

}

.descTitleS {
    font-size: 1.1rem;
    color: #555;
    font-weight: 400;
    line-height: 1.3rem;
}

.descTextBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.descText {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #444;
}

.service {
    padding: 1rem 5%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
}

.serviceTitle {
    font-weight: 600;
    font-size: 2rem;
    color: var(--blue);
}

.serviceText {
    color: var(--color);
    font-size: 1rem;
    line-height: 1.3rem;
}

.serviceCardBox {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 2rem;
}

.serviceCard {
    width: calc(43% - 1rem);
    display: flex;
    flex-direction: row;
    padding: 2rem 2% 2rem 5%;
    gap: 5%;
    box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
}

.cardTextBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cardTitle {
    font-size: 1.3rem;
    font-weight: 600;
}

.cardText {
    font-size: 0.9rem;
    line-height: 1.3rem;
}

.action {
    display: flex;
    flex-direction: column;
    background-color: var(--lightOrange);
    padding: 3rem 5%;
    gap: 2rem;
    align-items: center;
}

.actionLeft {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.actionRight {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.actionRight button {
    padding: 0.7rem 1.2rem;
    color: var(--color);
    font-size: 1rem;
    font-weight: 600;
    background-color: #fff;
    border: 0.1rem solid var(--color);
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
     width: 1.25rem;
     height: 1.25rem;
    }
}

.actionRight button:hover {
    background: var(--white);
    color: var(--color);
}

.actionText {
    font-weight: 500;
    font-size: 1rem;
    color: var(--color);
    text-align: center;
    line-height: 1.5rem;
}

.contact {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 5%;
}

.contactTitle {
    font-size: 1.7rem;
    font-weight: 600;
    color: var(--blue);
}

.contactText {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.4rem;
    text-align: center;
}

.contactBox {
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;
    justify-content: center;
}

.contactCard {
    width: calc(30% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 2rem 1rem;
    gap: 1rem;
}

.contactCardTitle {
    font-weight: 600;
    font-size: 1rem;
}

.contactCardText {
    font-size: 0.9rem;
    text-align: center;
    line-height: 1.3rem;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 2rem 5%;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.inputBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

input {
    padding: 0.6rem 0.8rem;
    font-size: 1.1rem;
    border: 0.1rem solid #dee2e6;
}

.inputName {
    width: 45%;
}

.inputEmail {
    width: 45%;
}

.inputSub {
    width: 97.5%;
}

.inputMsg {
    height: 5rem;
    padding: 0.6rem 0.8rem;
    font-size: 1.1rem;
    border: 0.1rem solid #dee2e6;
    width: 97.5%;
}

.btn {
    padding: 0.7rem 1.2rem;
    color: var(--white);
    border: 0.1rem solid var(--lightOrange);
    background-color: var(--lightOrange);
    font-size: 1.2rem;
    border-radius: 0.5rem;

}



.link {
    color: var(--blue);
    font-weight: 600;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
    font-weight: 800;
}

.linkBlue {
    color: var(--blue);
    font-weight: 600;
    cursor: pointer;
}

button {
    cursor: pointer;
}



`

