import React from 'react'
import { styled } from 'styled-components'
import Header from '../components/Header';
import Footer from '../components/Footer';
import bg1 from '../assets/bgtop1.jpg'
import img1 from '../assets/product-service.jpg'
import img2 from '../assets/SolutionEnterprise.png'
import { useNavigate } from 'react-router';

const ProductService = () => {
    const navigate = useNavigate();

    const handleOpenRegortalent = () => {
        window.open("https://ai.regortalent.com", "_blank");
    }

    return (
        <StyledPage>
            <Header />
            <StyledContent>
                <span className='mainTitle'>Products and Services: Powering Growth and Efficiency with AI</span>

                <div className='top'>
                    <span className='topText'>At Regor Labs, we offer a comprehensive suite of AI-based technology solutions designed to revolutionize the way businesses operate, solve critical challenges, and accelerate growth while optimizing costs. Our commitment to innovation and excellence drives us to deliver cutting-edge products and services tailored to your unique needs.</span>
                </div>

                <span className='title'>AI-Based Solutions for Business Transformation</span>

                <div className='mainBox part'>

                    <div className='leftBox'>
                        <span className='smallTitle'>RegorTalent - AI-Based Recruitment Solution</span>

                        <div className='card'>
                            <span className='cardText'><b>Revolutionize Your Hiring: </b> Say goodbye to traditional recruitment processes. With RegorTalent, our AI-based recruitment solution, you can streamline your hiring process, from candidate sourcing to selection. RegorTalent uses advanced algorithms to match candidates with job requirements, reduce bias, and improve the quality of your hires. It's the future of talent acquisition.</span>
                        </div>
                    </div>

                    <div className='rightBox'>
                        <img src={img1} />
                    </div>

                </div>


                <div className='mainBox'>

                    <div className='rightBox'>
                        <img src={img2} />
                    </div>

                    <div className='leftBox'>
                        <span className='smallTitle'>Data Analytics and Insights</span>

                        <div className='card'>
                            <span className='cardText'><b>Data-Driven Decision-Making: </b>Harness the power of data with our AI-driven analytics solutions. We help you extract valuable insights from your data, enabling you to make informed decisions, optimize operations, and uncover new growth opportunities.</span>
                        </div>
                    </div>

                </div>

                <div className='cardBox1 part'>
                    <div className='card1'>
                        <span className='cardTitle1'>Chatbots and Virtual Assistants</span>
                        <span className='cardText1'><b>Enhanced Customer Engagement: </b>Transform customer interactions with AI-powered chatbots and virtual assistants. Provide 24/7 support, answer frequently asked questions, and deliver personalized experiences, all while reducing operational costs.</span>
                    </div>

                    <div className='card1'>
                        <span className='cardTitle1'>Predictive Maintenance</span>
                        <span className='cardText1'><b>Maximize Asset Efficiency: </b>Prevent costly equipment breakdowns and downtime with our predictive maintenance solutions. Using AI algorithms, we predict maintenance needs, extending the life of your assets and minimizing operational disruptions.</span>
                    </div>

                    <div className='card1'>
                        <span className='cardTitle1'>Fraud Detection and Prevention</span>
                        <span className='cardText1'><b>Protect Your Business: </b>Safeguard your organization from fraudulent activities with our AI-driven fraud detection systems. We analyze transaction data in real-time to identify and prevent potential threats, saving you both money and reputation.</span>
                    </div>

                    <div className='card1'>
                        <span className='cardTitle1'>Personalized Marketing</span>
                        <span className='cardText1'><b>Connect with Your Audience: </b> Improve customer engagement and conversion rates with AI-driven personalized marketing campaigns. Our solutions analyze user behavior and preferences to deliver targeted content and offers.</span>
                    </div>
                </div>


                <span className='title'>Why Choose Regor Labs</span>

                <span className='text'><b>Expertise</b>: Our team of AI specialists and data scientists is at the forefront of technological advancements, ensuring that you receive the highest level of expertise and innovation.</span>
                <span className='text'><b>Business-Centric Approach</b>: We understand that technology should serve your strategic goals. Our solutions are designed with your specific business objectives in mind.</span>
                <span className='text'><b>Cost Optimization</b>: We know that controlling costs is essential for sustainable growth. Our AI solutions are optimized to enhance efficiency and reduce expenses.</span>
                <span className='text'><b>Customized Solutions</b>: Every business is unique, and so are our solutions. We work closely with you to tailor our products and services to fit your organization's precise needs.</span>

                <span className='title'>Join Us in the AI Revolution</span>

                <span className='text'>Regor Labs invites you to join us on a transformative journey where innovation, efficiency, and growth converge. Whether you're a startup or an established enterprise, we're here to help you realize the full potential of AI-based technology solutions.</span>

                <div className='btnBox'>
                    <button className='btn' onClick={() => navigate('/contact')}>Contact Us</button>
                    <button className='btn' onClick={() => handleOpenRegortalent()}>Visit RegorTalent</button>
                </div>


                <span className='text'>Experience the future of business with Regor Labs. Contact us today to explore how our AI-driven products and services can elevate your organization to new heights while optimizing costs across industries.</span>
            </StyledContent>
            <Footer />
        </StyledPage>
    )
}

export default ProductService;

export const StyledPage = styled.div`
width: 100%;
background-color: var(--white);
color: black;
display: flex;
flex-direction: column;

`

export const StyledContent = styled.div`
margin-top: 7rem;
margin-bottom: 3rem;
display: flex;
flex-direction: column;
margin-left: 10%;
margin-right: 10%;
gap: 1rem;

.mainTitle {
    font-weight: 900;
    font-size: 1.3rem;
    padding: 0.5rem 0;
    text-align: center;
}

.top {
    background-image: url(${bg1});
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--white);
    padding: 3rem 5%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 1rem;
    align-items: center;
}

.topTitle {
    font-size: 1.8rem;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 2.3rem;
    text-align: center;
}

.topText {
    font-size: 1.1rem;
    text-align: center;
}

.smallTitle {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.mainBox {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.part {
    margin-top: 2rem;
}

.leftBox {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.rightBox {
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: start;

}

.rightBox > img {
    width: 55%;
}


.card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--color);
}


.cardText {
    font-size: 1rem;
    line-height: 1.5rem;
}

.title {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    padding: 2rem 0rem 2rem 0rem;
}


.text {
    word-wrap: break-word;
    font-size: 0.8rem;
    line-height: 1.5rem;
    font-weight: 500;
    color:  rgb(70, 78, 98);
}

.linkNav {
    color: var(--lightOrange);

}

.linkNav:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
}

.btnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    gap: 2rem;
}

.btn {
    padding: 0.7rem 1.2rem;
    background-color: var(--lightOrange);
    border: 0.1rem solid var(--lightOrange);
    border-radius: 0.5rem;
    color: var(--white);
    font-size: 1.2rem;
    cursor: pointer;
}

.btn:hover {
    background-color: var(--white);
    color: var(--color);
}


.cardBox1 {
    display: flex;
    flex-flow: row wrap;
    column-gap: 5%;
    row-gap: 2rem;
    background: linear-gradient(to bottom, grey, black);
    color: white;
    padding: 2rem 5%;
    border-radius: 1rem;
    justify-content: center;
}

.card1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.cardTitle1 {
    font-size: 1.1rem;
    font-weight: 600;
}

.cardText1 {
    text-align: start;
    font-size: 1rem;
    line-height: 1.5rem;
}

`

