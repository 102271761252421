import React from 'react'
import { styled } from 'styled-components'
import Header from '../components/Header';
import Footer from '../components/Footer';
import bg1 from '../assets/bgtop1.jpg'
import img1 from '../assets/about.jpg'
import img2 from '../assets/about1.png'
import { useNavigate } from 'react-router';

const About = () => {
    const navigate = useNavigate();

    const handleOpenRegortalent = () => {
        window.open("https://ai.regortalent.com", "_blank");
    }

    return (
        <StyledPage>
            <Header />
            <StyledContent>
                <span className='mainTitle'>About Regor Labs: Pioneering AI-Driven Growth and Efficiency</span>

                <div className='top'>
                    <span className='topTitle'>Who We Are</span>
                    <span className='topText'>At Regor Labs, we're on a mission to reshape the future of business by harnessing the immense power of AI-based technology solutions. Established at the intersection of innovation and enterprise, Regor Labs is committed to helping businesses thrive, grow, and exercise precise control over costs in an ever-evolving digital landscape.</span>
                </div>


                <div className='mainBox part'>

                    <div className='leftBox'>
                        <span className='smallTitle'>Our Vision</span>

                        <div className='card'>
                            <span className='cardText'>We envision a world where businesses of all sizes have access to cutting-edge AI technology that empowers them to reach their full potential. We strive to be the driving force behind this transformation, leading the charge toward a more intelligent and efficient future.</span>
                        </div>
                    </div>

                    <div className='rightBox'>
                        <img src={img1} />
                    </div>

                </div>


                <div className='mainBox'>

                    <div className='rightBox'>
                        <img src={img2} />
                    </div>

                    <div className='leftBox'>
                        <span className='smallTitle'>Our Expertise</span>

                        <div className='card'>
                            <span className='cardText'>With a team of dedicated experts in artificial intelligence, data science, and technology, we specialize in creating tailor-made solutions that address the unique challenges faced by businesses today. Our expertise spans various industries, allowing us to deliver innovative AI-driven strategies that foster growth and enhance competitiveness.</span>
                        </div>
                    </div>

                </div>

                <span className='title'>What Sets Us Apart</span>

                <span className='text'><b>AI Excellence</b>: We're not just technology enthusiasts; we're AI pioneers. Our commitment to staying at the forefront of AI innovation ensures that our solutions are always cutting-edge.</span>
                <span className='text'><b>Business-Centric Approach</b>: We understand that technology is a means to an end, not an end in itself. That's why we take a business-centric approach, aligning our solutions with your strategic goals.</span>
                <span className='text'><b>Cost Control</b>: We recognize the importance of controlling costs while pursuing growth. Our AI solutions are designed to optimize operations and maximize efficiency, effectively managing your budget.</span>
                <span className='text'><b>Tailored Solutions</b>: No two businesses are alike, and neither are our solutions. We work closely with you to understand your unique needs and create customized AI solutions that fit your organization perfectly.</span>

                <span className='title'>Our Commitment</span>

                <span className='title1'>At Regor Labs, we are committed to:</span>

                <span className='text'><b>Innovation</b>: Continuously pushing the boundaries of AI technology to deliver solutions that drive your business forward.</span>
                <span className='text'><b>Customer Success</b>: Your success is our success. We measure our achievements by the growth and prosperity of our clients.</span>
                <span className='text'><b>Ethical Practices</b>: We uphold the highest ethical standards in all our endeavors, ensuring the responsible use of AI technology.</span>

                <span className='title'>Join Us on the Journey</span>

                <span className='text'>We invite you to join us on this transformative journey. Whether you're a small startup or a global enterprise,Regor Labs is your trusted partner in realizing the full potential of AI-based technology solutions. Together, we'll pave the way to a future where growth and cost control go hand in hand.</span>

                <div className='btnBox'>
                <button className='btn' onClick={() => navigate('/contact')}>Contact Us</button>
                <button className='btn' onClick={() => handleOpenRegortalent()}>Visit RegorTalent</button>
                </div>
                

                <span className='text'>Discover the Regor Labs difference â€“ where innovation, expertise, and commitment converge to drive your business to new heights. Contact us today to explore the possibilities!</span>
            </StyledContent>
            <Footer />
        </StyledPage>
    )
}

export default About;

export const StyledPage = styled.div`
width: 100%;
background-color: var(--white);
color: black;
display: flex;
flex-direction: column;

`

export const StyledContent = styled.div`
margin-top: 7rem;
margin-bottom: 3rem;
display: flex;
flex-direction: column;
margin-left: 10%;
margin-right: 10%;
gap: 1rem;

.mainTitle {
    font-weight: 900;
    font-size: 1.3rem;
    padding: 0.5rem 0;
    text-align: center;
}

.top {
    background-image: url(${bg1});
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--white);
    padding: 3rem 5%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 1rem;
    align-items: center;
}

.topTitle {
    font-size: 1.8rem;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 2.3rem;
    text-align: center;
}

.topText {
    font-size: 1.1rem;
    text-align: center;
}

.smallTitle {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.mainBox {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.part {
    margin-top: 2rem;
}

.leftBox {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.rightBox {
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: start;

}

.rightBox > img {
    width: 55%;
}


.card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--color);
}


.cardText {
    font-size: 1rem;
    line-height: 1.5rem;
}

.title {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    padding: 2rem 0rem 2rem 0rem;
}


.text {
    word-wrap: break-word;
    font-size: 0.8rem;
    line-height: 1.5rem;
    font-weight: 500;
    color:  rgb(70, 78, 98);
}

.linkNav {
    color: var(--lightOrange);

}

.linkNav:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
}

.btnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    gap: 2rem;
}

.btn {
    padding: 0.7rem 1.2rem;
    background-color: var(--lightOrange);
    border: 0.1rem solid  var(--lightOrange);
    border-radius: 0.5rem;
    color: var(--white);
    font-size: 1.2rem;
    cursor: pointer;
}

.btn:hover {
    background-color: var(--white);
    color: var(--color);
}

`

