import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ProductService from './pages/ProductService';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Term from './pages/Term';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/product-service' element={<ProductService />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />

          <Route path='/privacy' element={<Privacy />} />
          <Route path='/term' element={<Term />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
