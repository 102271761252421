import React from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import LandingContent from '../components/LandingContent'
import Footer from '../components/Footer'

const LandingPage = () => {
    return (
        <StyledBox>
            <Header />

            <StyledContent>
                <LandingContent/>
                <Footer/>
            </StyledContent>
        </StyledBox>
    )
}

export default LandingPage


const StyledBox = styled.div`
display: flex;
flex-direction: column;


`


const StyledContent = styled.div`
margin-top: 4rem;

`