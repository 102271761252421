import React from 'react'
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <StyledBox>
            <div className='privacy'>
                <span onClick={() => navigate('/privacy')}>Privacy Policy</span>
                <span onClick={() => navigate('/term')}>Terms of Use</span>
            </div>

            <span className='bottomText'>Copyright © 2024 Regor Software Labs Private Limited</span>
        </StyledBox>
    )
}

export default Footer;


const StyledBox = styled.div`
// background-color: #F0B27A;

.bottomText {
    font-size: 0.9rem;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    font-weight: 500;
    
    
}

.privacy {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    gap: 2rem;
    font-size: 0.85rem;
}

.privacy span {
    cursor: pointer;
}

.privacy span:hover {
    font-weight: 600;
}

`